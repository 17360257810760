.login-container {
    background-color: rgba(255,255,255,0.6);
    width:100%;
    height:100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-modal {
    border: solid 1px black;
    margin: auto;
    margin-left: 10vw;
    margin-right: 10vw;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 20vh;
    padding-bottom: 20vh;
    background-color: rgb(255,255,255);
}